import {Action, History, Location} from "history"
import {IObjectDidChange, observable, observe, runInAction} from "mobx"
import {Authorisation} from "./Authorisation"
import {route} from "../lib/functions/route"
import * as queryParser from "querystring"

export class Router {
    @observable path: string
    @observable search: SearchQuery = {}

    constructor(private history: History, private auth: Authorisation) {
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH")

        observe(auth, this.onAuthorisationChange.bind(this))
    }

    private onHistoryChange(location: Location, action: Action) {
        let query = location.search
        if (query.charAt(0) === "?") {
            query = query.substring(1)
        }
        runInAction(() => {
            this.search = queryParser.parse(query)
            this.path = location.pathname
        })

        this.doRedirects()
        this.scrollUp()
    }

    private scrollUp() {
        let element = document.getElementById("main-content")
        if (element) {
            element.scroll(0, 0)
        }
    }

    private doRedirects() {
        const path = this.path

        if (route("(/)", path)) {
            // this.goTo("/clients")
            return
        }
    }

    private onAuthorisationChange(change: IObjectDidChange) {
        if (change.name !== "status") {
            // Parameter not changed
            return
        }

        const path = this.path
        const status = this.auth.status

        if (status === "authorised" && this.auth.authorisedByToken === false) {
            this.goTo("/")
            return
        }

        const isSignInOrSignUpURL = route("/sign-in(/)", path) || route("/sign-up(/)", path)
        if (status === "authorised" && isSignInOrSignUpURL) {
            this.goTo("/")
            return
        }

        if (status === "guest") {
            if (!this.isGuestAllowed()) {
                this.goTo("/sign-in")
            }
        }
    }

    private isGuestAllowed(): boolean {
        // if (route("/sign-up(/)", this.path)) {
        //     return true
        // }
        if (route("/accounts/recovery(/)", this.path)) {
            return true
        }

        if (route("/accounts/reset(/)*", this.path)) {
            return true
        }

        return false
    }

    public goTo(url: string) {
        console.log(`GO TO URL:${url}`)
        this.history.push(url)
    }
}

export interface SearchQuery {
    popupType?
    popupObjectId?
}
