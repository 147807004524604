import React, {Component, KeyboardEvent} from "react"
import styles from "./SignUp.module.css"
import {LoginWrapper} from "components/LoginWrapper"
import {Button} from "pages/sign-in/parts/Button"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {CheckBox} from "components/CheckBox"
import {KlassDropdown} from "components/inputs/KlassDropdown"
import {Captcha} from "components/Captcha"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {Authorisation} from "models/Authorisation"
import {Link} from "react-router-dom"
import {
    CHECK_PASSWORD_OPTIONS,
    findErrors,
    findPasswordErrors
} from "../../lib/functions/findErrors"
import {Model} from "Model"
import {LabelPassword} from "components/inputs/LabelPassword"

interface Props {
    auth: Authorisation
    model: Model
}

export interface SignUpUser {
    first_name?
    last_name?
    email?
    password?
    confirm_password?
    json_data: {
        phone_number?: string
        sms_permission?
        crm_id?
        rep_code?
        lead_source?
    }
    captcha_value?: string
}

const optionsYN = [
    {label: "Yes", value: "Yes"},
    {label: "No", value: "No"}
]

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    first_name: {...mandatory, length: {minimum: 3}},
    last_name: {...mandatory, length: {minimum: 3}},
    email: {
        email: true
    },
    password: {
        ...mandatory,
        length: {minimum: 3}
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    },
    captcha_value: mandatory
}

const nestedRules = {
    phone_number: mandatory
}

const customPasswordConfirmRule = {
    password: {
        ...mandatory,
        length: {minimum: 3},
        format: {
            pattern: /^.{8,}$/,
            flags: "gm",
            message: "^password needs to have at least 8 chars at minimum."
        }
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    }
}

@observer
export class SignUp extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private nestedErrors: {[key: string]: string} = {}

    @observable
    private backendErrors: string | null = ""

    @observable
    private passwordOptions: any = {
        options: CHECK_PASSWORD_OPTIONS.default
    }

    @observable
    isVisible: boolean = false

    @observable
    private user: SignUpUser = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        json_data: {phone_number: "", sms_permission: "no", crm_id: "", rep_code: ""},
        captcha_value: ""
    }

    private OTHER_CONSTANT = {
        keyOther: "about_us",
        valueOther: "Other"
    }

    private recaptchaRef: any = React.createRef()

    private onChangeCheckbox = (id: string, value: any) => {
        this.user.json_data.sms_permission = value
    }

    private onChangeCaptchaText = (id: string, value: string) => {
        this.user[id] = value
    }

    private isValid = () => {
        const {isValid, errors} = findErrors(this.user, rules)

        const {isValid: nestedIsValid, errors: nestedErrors} = findErrors(
            this.user.json_data,
            nestedRules
        )
        this.errors = errors
        this.nestedErrors = nestedErrors
        const {isValid: isValidPassword} = this.passwordOptions
        this.isVisible = !isValidPassword
        // console.log("@@@@@@@ isValid SignUp:", {
        //     errors: {
        //         isValid,
        //         errors: toJS(this.errors)
        //     },
        //     nestedErrors: {
        //         nestedIsValid,
        //         errors: toJS(this.nestedErrors)
        //     },
        //     passwordError: toJS(this.passwordOptions)
        // })
        return isValid && nestedIsValid && isValidPassword
    }

    private isValidPassword = () => {
        const {isValid, options} = findPasswordErrors(this.user)
        this.passwordOptions = {
            isValid,
            options
        }
    }

    private onKeyUpPassword = (event: KeyboardEvent<Element>) => {
        this.isValidPassword()
    }

    private isValidConfirmPassword = () => {
        const {isValid, errors} = findErrors(this.user, customPasswordConfirmRule)
        this.errors = errors
    }

    private onKeyUpConfirmPassword = (event: KeyboardEvent<Element>) => {
        this.isValidConfirmPassword()
    }

    public render() {
        const user = this.user
        // const {model} = this.props
        const {
            onChangeCaptchaText,
            backendErrors,
            onKeyUpPassword,
            onKeyUpConfirmPassword,
            isVisible
        } = this
        const {valueOther} = this.OTHER_CONSTANT
        return (
            <LoginWrapper className={styles.wrapper}>
                <div className={styles.root}>
                    <header className={styles.header}>
                        <h2 className={styles.h2}>Create your secure portal</h2>
                        <Link to="/sign-in" className={styles.link}>
                            Sign In
                        </Link>
                    </header>
                    <p className={styles.text}>
                        Please create your account for the secure portal to protect any information
                        that you share.
                    </p>
                    <br />
                    <p className={styles.text}>
                        Please complete the following information and use the Register button to
                        begin the create your secure portal.
                    </p>
                    <div className={styles.formArea}>
                        <Label
                            text="Email Address"
                            className={styles.inputUser}
                            error={this.errors["email"]}
                            required>
                            <Input
                                onChange={(v) => {
                                    user.email = v
                                }}
                                value={user.email}
                                type="email"
                            />
                        </Label>

                        <LabelPassword
                            text="Password"
                            required
                            className={styles.inputPass}
                            isVisible={isVisible}
                            minChars={8}
                            options={this.passwordOptions.options}>
                            <Input
                                onChange={(v) => {
                                    user.password = v
                                }}
                                value={user.password}
                                type="password"
                                onKeyUp={onKeyUpPassword}
                                onFocus={() => (this.isVisible = true)}
                                onBlur={() =>
                                    (this.isVisible =
                                        this.passwordOptions.options.value !==
                                            CHECK_PASSWORD_OPTIONS.values.strong &&
                                        this.passwordOptions.options.value !==
                                            CHECK_PASSWORD_OPTIONS.values.medium)
                                }
                            />
                        </LabelPassword>

                        <Label
                            text="Confirm Password"
                            required
                            className={styles.inputPass}
                            error={this.errors["confirm_password"]}>
                            <Input
                                onChange={(v) => {
                                    user.confirm_password = v
                                }}
                                value={user.confirm_password}
                                type="password"
                                onKeyUp={onKeyUpConfirmPassword}
                            />
                        </Label>

                        <Label
                            text="First Name"
                            required
                            className={styles.inputPass}
                            error={this.errors["first_name"]}>
                            <Input
                                onChange={(v) => {
                                    user.first_name = v
                                }}
                                value={user.first_name}
                            />
                        </Label>

                        <Label
                            text="Last Name"
                            required
                            className={styles.inputPass}
                            error={this.errors["last_name"]}>
                            <Input
                                onChange={(v) => {
                                    user.last_name = v
                                }}
                                value={user.last_name}
                            />
                        </Label>

                        <Label
                            text="Phone Number"
                            required
                            className={styles.inputPass}
                            error={this.nestedErrors["phone_number"]}>
                            <Input
                                onChange={(v) => {
                                    user.json_data.phone_number = v
                                }}
                                value={user.json_data.phone_number}
                            />
                        </Label>

                        <Label
                            text="Accept SMS Message?"
                            required
                            error={this.nestedErrors["sms_permission"]}>
                            <KlassDropdown
                                onChange={(v) => {
                                    user.json_data.sms_permission = v.value
                                }}
                                options={optionsYN}
                                value={optionsYN.find((el) => {
                                    return el.value === user.json_data.sms_permission
                                })}
                            />
                        </Label>

                        {/* <CheckBox
                            id={`sms_permission`}
                            onChange={this.onChangeCheckbox}
                            value={user.json_data.sms_permission}
                        />
                        <span className={styles.fontSize14}>SMS Permission</span> */}

                        <Captcha
                            recaptchaRef={this.recaptchaRef}
                            id={`captcha_value`}
                            user={this.user}
                            onChange={onChangeCaptchaText}
                            errors={this.errors}
                            backendErrors={backendErrors}
                        />
                    </div>
                    <p className={styles.textUnderForm}>
                        Please make a note of your email address and password as they will be
                        required should you wish to make application changes in the future.
                    </p>

                    <Button
                        text="SIGN UP"
                        onClick={this.signUp.bind(this)}
                        className={styles.button}
                    />
                </div>
            </LoginWrapper>
        )
    }

    private async signUp() {
        this.backendErrors = ""
        const CAPTCHA_WORD = "Captcha"
        if (this.recaptchaRef.current) {
            const captchaToken = await this.recaptchaRef.current.executeAsync()
            console.log("@@@ signUp captch_value", captchaToken)
            this.user.captcha_value = captchaToken
            this.recaptchaRef.current.reset()
        }
        if (this.isValid()) {
            this.user.json_data.lead_source = localStorage.getItem("lead_source")
            const response = await this.props.auth.signUp("spatech", {...this.user})
            if (this.recaptchaRef.current) {
                this.recaptchaRef.current.reset()
                this.user.captcha_value = ""
            }
            if (!response.success) {
                const {
                    errors: [{message}]
                } = response
                if (message.includes(CAPTCHA_WORD)) {
                    this.backendErrors = message
                }
            }
        }
    }
}
