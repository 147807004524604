import React, {Component} from "react"
import styles from "./Footer.module.css"

const campuses = [
    {
        id: 1,
        region_id: 1,
        name: "NORTH ANDOVER",
        code: "North Andover",
        address: "202 Sutton Street, Suite 460",
        state: "North Andover, MA 01845",
        phone: "978-620-5250",
        school_code: "",
        region: {
            id: 1,
            state: "California",
            region: "California"
        }
    }
]
const campuses1 = [
    {
        id: 1,
        region_id: 1,
        name: "PLYMOUTH",
        code: "Plymouth",
        address: "59 Industrial Park Rd",
        state: "Plymouth, MA 02360",
        phone: "508-747-3130",
        school_code: "",
        region: {
            id: 1,
            state: "California",
            region: "California"
        }
    }
]
const campuses2 = [
    {
        id: 1,
        region_id: 1,
        name: "WESTBOROUGH",
        code: "Westborough",
        address: "227 Turnpike Rd Suite 1",
        state: "Westborough, MA 01581",
        phone: "508-836-8864",
        school_code: "",
        region: {
            id: 1,
            state: "California",
            region: "California"
        }
    }
]
const campuses3 = [
    {
        id: 1,
        region_id: 1,
        name: "WESTBROOK",
        code: "Westbrook",
        address: "100 Larrabee Rd",
        state: "Westbrook, ME 04092",
        phone: "207-591-4141",
        school_code: "",
        region: {
            id: 1,
            state: "California",
            region: "California"
        }
    }
]

const social = [
    {
        id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/SpaTechInstitute",
        icon: "fa fa-facebook"
    },
    {
        id: 2,
        name: "Instagram",
        link: "https://www.instagram.com/spatechinstitute_",
        icon: "fa fa-instagram"
    },
    {
        id: 3,
        name: "Twitter",
        link: "https://twitter.com/SpaTech",
        icon: "fa fa-twitter"
    },
    {
        id: 4,
        name: "Youtube",
        link: "https://www.youtube.com/channel/UC0lZpkOo0LTnQfgyq_8R_Zw",
        icon: "fa fa-youtube"
    }
]

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <div>
                        <div>
                            <img
                                src="/logo-spatech.png"
                                alt="logo-spatech"
                                className={styles.logo}
                            />
                        </div>
                        <div className={styles.social}>
                            {social.map(({id, link, icon}) => (
                                <a
                                    href={link}
                                    className={icon}
                                    key={id}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {""}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses.map(({code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses1.map(({code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses2.map(({code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses3.map(({code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </footer>
        )
    }
}
